import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { formatRfcTimeStringToViewFormat } from '../../../../utils/dateAndTime/dateAndTime';
import { TimeSlotAvailabilityStatus } from '../../../../utils/timeSlots/timeSlots';
import settingsParams from '../../settingsParams';

export interface TimeSlotStatus extends TimeSlotAvailabilityStatus {
  selected: boolean;
}

export interface TimeSlot {
  formattedStartTime: string;
  rfcStartTime: string;
  status: TimeSlotStatus;
  ariaLabel: string;
}

export type TimeSelectionViewModel = {
  timeSlots: TimeSlot[];
  shouldLimitNumberOfTimeSlotsDisplayed: boolean;
  maxNumberOfTimeSlotsToDisplay: number;
  showAllButtonText: string;
};

type TimeSelectionViewModelParams = ViewModelFactoryParams<
  CalendarState,
  CalendarContext
> & {
  timeSlotsAvailabilityStatuses: Map<string, TimeSlotAvailabilityStatus>;
};

export function createTimeSelectionViewModel({
  timeSlotsAvailabilityStatuses,
  state,
  context,
}: TimeSelectionViewModelParams): TimeSelectionViewModel {
  const { selectedTime } = state;
  const { businessInfo, t, settings } = context;
  const locale = businessInfo.dateRegionalSettingsLocale;

  const timeSlots: TimeSlot[] = [];
  timeSlotsAvailabilityStatuses.forEach(
    (timeSlotStatus: TimeSlotAvailabilityStatus, rfcStartTime: string) => {
      const formattedStartTime = rfcStartTime
        ? formatRfcTimeStringToViewFormat(rfcStartTime, locale)
        : '';
      const isTimeSelected = rfcStartTime === selectedTime;
      const ariaLabel = getTimeSlotAriaLabel(
        timeSlotStatus,
        formattedStartTime,
        t,
      );

      timeSlots.push({
        rfcStartTime,
        formattedStartTime,
        status: { ...timeSlotStatus, selected: isTimeSelected },
        ariaLabel,
      });
    },
  );

  return {
    timeSlots,
    shouldLimitNumberOfTimeSlotsDisplayed: settings.get(
      settingsParams.timePickerShouldLimitNumberOfTimeSlotsDisplayedPerDay,
    ),
    maxNumberOfTimeSlotsToDisplay: settings.get(
      settingsParams.timePickerMaxNumberOfTimeSlotsDisplayedPerDay,
    ),
    showAllButtonText: settings.get(settingsParams.timePickerShowAllButtonText),
  };
}

const getTimeSlotAriaLabel = (
  timeSlotStatus: TimeSlotAvailabilityStatus,
  formattedStartTime: string,
  t: TFunction,
): string => {
  if (timeSlotStatus.tooLateToBookAllSlots || timeSlotStatus.allSlotsAreFull) {
    return t(
      'app.time-picker.accessibility.time-slot-closed-for-registration',
      { time: formattedStartTime },
    );
  }
  if (timeSlotStatus.tooEarlyToBookAllSlots) {
    return t(
      'app.time-picker.accessibility.time-slot-not-open-yet-for-registration',
      { time: formattedStartTime },
    );
  }
  return formattedStartTime;
};
