import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import {
  DurationMapper,
  DurationMapperOptions,
} from '@wix/bookings-uou-mappers';
import { ServiceLocationType } from '@wix/bookings-uou-types';
import { DropdownOptionProps } from 'wix-ui-tpa/Dropdown';
import { SelectedSlotOption } from '../../components/BookingCalendar/Actions/onSlotOptionSelected/onSlotOptionSelected';
import { TFunction } from '../../components/BookingCalendar/controller';
import settingsParams from '../../components/BookingCalendar/settingsParams';
import { CalendarErrors, Selectables } from '../bi/consts';
import { minutesDifferencesBetweenTwoDates } from '../dateAndTime/dateAndTime';
import { Optional } from '../../types/types';
import { CalendarContext } from '../context/contextFactory';

type SelectableBookingDetailOptionError = {
  key: CalendarErrors;
  message: string;
};

export type SelectableBookingDetails = {
  key: string;
  options: SelectableBookingDetailsOption[];
  error: SelectableBookingDetailOptionError;
  headerText: string;
};

export type SelectableBookingDetailsOption = {
  id?: string;
  value?: string;
  isSelectable?: boolean;
};

const getDropDownSubtitle = (dropdownSubtitle: string) => {
  return {
    id: dropdownSubtitle,
    value: dropdownSubtitle,
    isSelectable: false,
    isSectionTitle: true,
  };
};

export const mapSelectableBookingDetailToDropdownOptions = (
  selectableBookingDetails: SelectableBookingDetailsOption[],
  subtitle: string,
): DropdownOptionProps[] => {
  const dropdownOptions: DropdownOptionProps[] = selectableBookingDetails.map(
    (selectableBookingDetailsOption) => ({
      ...selectableBookingDetailsOption,
      isSectionTitle: false,
    }),
  );

  if (dropdownOptions.length > 1) {
    const dropdownSubtitle = getDropDownSubtitle(subtitle);
    dropdownOptions.unshift(dropdownSubtitle);
  }

  return dropdownOptions;
};

export const getSelectables = ({
  selectableSlots,
  calendarErrors,
  t,
  settings,
  dateRegionalSettingsLocale,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  calendarErrors: CalendarErrors[];
  t: TFunction;
  settings: CalendarContext['settings'];
  dateRegionalSettingsLocale: string;
  selectedOptions?: SelectedSlotOption[];
}): SelectableBookingDetails[] => {
  const selectableStaffMembers = getSelectableStaffMembers({
    selectableSlots,
    t,
    settings,
    dateRegionalSettingsLocale,
    selectedOptions,
  });
  const selectableLocations = getSelectableLocations({
    selectableSlots,
    t,
    settings,
    dateRegionalSettingsLocale,
    selectedOptions,
  });
  const selectableDurations = getSelectableDurations({
    selectableSlots,
    t,
    settings,
    dateRegionalSettingsLocale,
    selectedOptions,
  });
  const selectables = [
    selectableLocations,
    selectableStaffMembers,
    selectableDurations,
  ];
  return selectables.map((selectable) => {
    const hasError = calendarErrors.includes(selectable.error.key);
    return {
      key: selectable.key,
      options: selectable.options,
      error: {
        key: selectable.error.key,
        message: hasError ? selectable.error.message : '',
      },
      headerText: selectable.headerText,
    };
  });
};

const getSelectableLocationOptions = ({
  selectableSlots,
  t,
  dateRegionalSettingsLocale,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  t: TFunction;
  dateRegionalSettingsLocale: string;
  selectedOptions?: SelectedSlotOption[];
}) => {
  const filteredSelectableLocation: any[] = [];

  selectableSlots.forEach((selectableSlot) => {
    const selectableSlotLocation = selectableSlot.slot?.location;
    const isLocationExist = isLocationExistOnFilteredSelectableLocation(
      selectableSlotLocation,
      filteredSelectableLocation,
      t,
    );
    !isLocationExist && filteredSelectableLocation.push(selectableSlot);
  });

  return filteredSelectableLocation.map((selectableSlot) => {
    const locationId = selectableSlot.slot?.location?.id;
    const locationText = getLocationText(selectableSlot.slot.location, t);

    return {
      id: locationId || locationText,
      value: locationText,
      isSelectable: isLocationOptionSelectable(
        t,
        dateRegionalSettingsLocale,
        selectableSlot.slot,
        selectedOptions,
      ),
    };
  });
};

export const getSelectableLocations = ({
  selectableSlots,
  t,
  settings,
  dateRegionalSettingsLocale,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  t: TFunction;
  settings: CalendarContext['settings'];
  dateRegionalSettingsLocale: string;
  selectedOptions?: SelectedSlotOption[];
}): SelectableBookingDetails => {
  const selectableLocationOptions = getSelectableLocationOptions({
    selectableSlots,
    t,
    dateRegionalSettingsLocale,
    selectedOptions,
  });

  return {
    key: Selectables.LOCATION,
    options: selectableLocationOptions,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
      message: t('app.booking-details.dropdowns.error.location.text'),
    },
    headerText: settings.get(settingsParams.bookingDetailsLocationDropDownText),
  };
};

export const getSelectableStaffMembers = ({
  selectableSlots,
  t,
  settings,
  dateRegionalSettingsLocale,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  t: TFunction;
  settings: CalendarContext['settings'];
  dateRegionalSettingsLocale: string;
  selectedOptions?: SelectedSlotOption[];
}): SelectableBookingDetails => {
  const selectableStaffMembersFiltered: SlotAvailability[] = [];
  selectableSlots.forEach((selectableSlot) => {
    const staffMemberId = selectableSlot.slot?.resource?.id;
    const isStaffMemberExist = selectableStaffMembersFiltered.some(
      (member) => member.slot?.resource?.id === staffMemberId,
    );
    !isStaffMemberExist && selectableStaffMembersFiltered.push(selectableSlot);
  });

  const selectableStaffMembers = selectableStaffMembersFiltered.map(
    (selectableSlot: SlotAvailability) => {
      const staffMemberName = selectableSlot.slot?.resource?.name!;
      const staffMemberId = selectableSlot.slot?.resource?.id;

      return {
        id: staffMemberId,
        value: staffMemberName,
        isSelectable: isStaffMemberOptionSelectable(
          t,
          dateRegionalSettingsLocale,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  return {
    key: Selectables.STAFF_MEMBER,
    options: selectableStaffMembers,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
      message: t('app.booking-details.dropdowns.error.staff-member.text'),
    },
    headerText: settings.get(
      settingsParams.bookingDetailsStaffMemberDropDownText,
    ),
  };
};

export const getSelectableDurations = ({
  selectableSlots,
  t,
  settings,
  dateRegionalSettingsLocale,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  t: TFunction;
  settings: CalendarContext['settings'];
  dateRegionalSettingsLocale: string;
  selectedOptions?: SelectedSlotOption[];
}): SelectableBookingDetails => {
  const selectableDurations = selectableSlots.map(
    (selectableSlot: SlotAvailability) => {
      const rfcStartTime = selectableSlot.slot?.start!;
      const rfcEndTime = selectableSlot.slot?.end!;
      const slotDuration = getSlotDuration(
        rfcStartTime,
        rfcEndTime,
        t,
        dateRegionalSettingsLocale,
      );

      return {
        id: slotDuration,
        value: slotDuration,
        isSelectable: isDurationOptionSelectable(
          t,
          selectableSlot.slot,
          selectedOptions,
        ),
      };
    },
  );

  const selectableDurationsFiltered: SelectableBookingDetailsOption[] = [];
  selectableDurations.forEach((duration) => {
    const isDurationExist = selectableDurationsFiltered.some(
      (selectableDuration) => selectableDuration.value === duration.value,
    );

    !isDurationExist && selectableDurationsFiltered.push(duration);
  });

  return {
    key: Selectables.DURATION,
    options: selectableDurationsFiltered,
    error: {
      key: CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
      message: t('app.booking-details.dropdowns.error.duration.text'),
    },
    headerText: settings.get(settingsParams.bookingDetailsDurationDropDownText),
  };
};

const isLocationOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedSlotOption[],
): boolean => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};

const isStaffMemberOptionSelectable = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedSlotOption[],
): boolean => {
  return (
    filterSlotByLocationSelectedOption({ t, slot, selectedOptions }) &&
    filterSlotByDurationSelectedOption(
      t,
      dateRegionalSettingsLocale,
      slot,
      selectedOptions,
    )
  );
};

const isDurationOptionSelectable = (
  t: TFunction,
  slot?: Slot,
  selectedOptions?: SelectedSlotOption[],
): boolean => {
  return (
    filterSlotByStaffMemberSelectedOption(slot, selectedOptions) &&
    filterSlotByLocationSelectedOption({ t, slot, selectedOptions })
  );
};

const filterSlotByLocationSelectedOption = ({
  t,
  slot,
  selectedOptions,
}: {
  t: TFunction;
  slot?: Slot;
  selectedOptions?: SelectedSlotOption[];
}): boolean => {
  const location = slot?.location?.id || getLocationText(slot?.location, t);
  const locationSelectedOption = selectedOptions?.filter(
    (selectedOption) => selectedOption.key === Selectables.LOCATION,
  );

  return (
    !locationSelectedOption?.[0] ||
    location === locationSelectedOption?.[0]?.value
  );
};

export const filterSlotByStaffMemberSelectedOption = (
  slot?: Slot,
  selectedOptions?: SelectedSlotOption[],
): boolean => {
  const staffMemberId = slot?.resource?.id;
  const staffMemberSelectedOption = selectedOptions?.filter(
    (selectedOption) => selectedOption.key === Selectables.STAFF_MEMBER,
  );

  return (
    !staffMemberSelectedOption?.[0] ||
    staffMemberId === staffMemberSelectedOption?.[0]?.value
  );
};

const filterSlotByDurationSelectedOption = (
  t: TFunction,
  dateRegionalSettingsLocale: string,
  slot?: Slot,
  selectedOptions?: SelectedSlotOption[],
) => {
  const rfcStartTime = slot?.start!;
  const rfcEndTime = slot?.end!;
  const duration = getSlotDuration(
    rfcStartTime,
    rfcEndTime,
    t,
    dateRegionalSettingsLocale,
  );

  const durationSelectedOption = selectedOptions?.filter(
    (selectedOption) => selectedOption.key === Selectables.DURATION,
  );

  return (
    !durationSelectedOption?.[0] ||
    duration === durationSelectedOption?.[0]?.value
  );
};

export const getSlotDuration = (
  rfcStartTime: string,
  rfcEndTime: string,
  t: TFunction,
  dateRegionalSettingsLocale: string,
): string => {
  const durationInMinutes = minutesDifferencesBetweenTwoDates(
    rfcStartTime,
    rfcEndTime,
  );

  const durationOptions: DurationMapperOptions = {
    hourUnit: 'duration.units.hours',
    hourAriaUnit: 'duration.units.aria-hours',
    minuteUnit: 'duration.units.minutes',
    minuteAriaUnit: 'duration.units.aria-minutes',
  };

  const durationMapper = new DurationMapper(
    dateRegionalSettingsLocale,
    durationOptions,
    t,
  );

  return durationMapper.durationTextFromMinutes(durationInMinutes);
};

const getLocationText = (
  selectableSlotLocation: any,
  t: TFunction,
): Optional<string> => {
  switch (selectableSlotLocation.type) {
    case ServiceLocationType.OWNER_BUSINESS:
      return selectableSlotLocation?.name;
    case ServiceLocationType.OWNER_CUSTOM:
      return selectableSlotLocation?.formattedAddress;
    case ServiceLocationType.CLIENT_PLACE:
      return t('app.booking-details.dropdowns.locations.client-place.text');
  }
};

const isLocationExistOnFilteredSelectableLocation = (
  location: any,
  filteredSelectableLocation: any[],
  t: TFunction,
) => {
  const locationId = location?.id;
  const locationType = location?.type;
  const locationText = getLocationText(location, t);
  switch (locationType) {
    case ServiceLocationType.OWNER_BUSINESS:
      return filteredSelectableLocation.some((selectableSlot) => {
        const selectableLocation = locationId
          ? selectableSlot.slot?.location?.id
          : selectableSlot.slot?.location?.name;
        return selectableLocation === locationText;
      });
    case ServiceLocationType.OWNER_CUSTOM:
      return filteredSelectableLocation.some(
        (selectableSlot) =>
          selectableSlot.slot?.location?.formattedAddress === locationText,
      );
    case ServiceLocationType.CLIENT_PLACE:
      return filteredSelectableLocation.some(
        (selectableSlot) => selectableSlot.slot?.location.type === locationType,
      );
  }
};
