import { ActionFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { CalendarState, TFunction } from '../../controller';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import {
  getSelectables,
  getSlotDuration,
  SelectableBookingDetails,
} from '../../../../utils/selectableBookingDetails/selectableBookingDetails';
import { updateCalendarErrors } from './calendarErrorsHandler';
import {
  Slot,
  SlotAvailability,
} from '@wix/ambassador-availability-calendar/types';
import { Service, ServiceType, SlotDetails } from '@wix/bookings-uou-types';
import {
  CalendarErrors,
  SetError,
  BOOKINGS_CALENDAR_REFERRAL_INFO,
  Selectables,
} from '../../../../utils/bi/consts';
import { SetCalendarErrors } from '../setCalendarErrors/setCalendarErrors';
import { SelectedSlotOption } from '../onSlotOptionSelected/onSlotOptionSelected';
import { CALENDAR_PAGE_URL_PATH_PARAM } from '../../../../api/CalendarApi';
import { DialogType } from '../../ViewModel/dialogViewModel/dialogViewModel';

export const submitErrors = [
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_LOCATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_DURATION,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_SELECTED_STAFF_MEMBER,
  CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR,
];
export type OnSubmit = () => void;

export function createOnSubmitAction(
  {
    getControllerState,
    context: { t, businessInfo, settings, biLogger, wixSdkAdapter },
  }: ActionFactoryParams<CalendarState, CalendarContext>,
  setCalendarErrorsAction: SetCalendarErrors,
): OnSubmit {
  return async () => {
    const [state, setState] = getControllerState();
    const {
      selectedTime,
      selectableSlots,
      selectedOptions,
      calendarErrors,
      selectedService,
    } = state;
    const isRescheduling = !!state.rescheduleBookingDetails;

    if (!selectedTime) {
      const calendarError =
        CalendarErrors.SELECTED_SLOT_VALIDATION_NO_TIME_SELECTED_ERROR;

      void biLogger.bookingsPaymentMethodSelectionNextClicked({
        userMessage: calendarError,
      });
      setCalendarErrorsAction(calendarError, SetError.ADD);
    }
    const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
    if (selectableSlots) {
      const selectableBookingDetails = getSelectables({
        selectableSlots,
        calendarErrors,
        t,
        settings,
        dateRegionalSettingsLocale,
        selectedOptions,
      });

      updateCalendarErrors(
        selectableBookingDetails,
        setCalendarErrorsAction,
        selectedOptions,
      );

      const isCalendarErrorsHasSubmitErrors = calendarErrors.some((error) =>
        submitErrors.includes(error),
      );

      const selectedSlot = getSelectedSlot({
        selectableSlots,
        dateRegionalSettingsLocale,
        t,
        selectedOptions,
      });

      if (!isCalendarErrorsHasSubmitErrors && selectedSlot.length > 0) {
        if (isRescheduling) {
          setState({
            dialog: DialogType.RescheduleConfirm,
          });
        } else {
          const slug = await wixSdkAdapter.getServiceSlug(
            CALENDAR_PAGE_URL_PATH_PARAM,
          );
          const slot = selectedSlot[0].slot;
          const slotDetails: SlotDetails = getSlotDetails({
            slug,
            slot,
            selectedService,
          });

          void biLogger.bookingsContactInfoSaveSuccess({
            selectedSlot: selectedTime,
          });
          await wixSdkAdapter.navigateToBookingsContactInfoPage(
            slotDetails,
            BOOKINGS_CALENDAR_REFERRAL_INFO,
          );
        }
      } else {
        void biLogger.bookingsPaymentMethodSelectionNextClicked({
          selectedSlot: selectedTime,
        });
      }
    }
  };
}

const getSlotDetails = ({
  slug,
  slot,
  selectedService,
}: {
  slug: string;
  slot?: Slot;
  selectedService: Service;
}): SlotDetails => {
  const isClass = selectedService.info.type === ServiceType.GROUP;
  return {
    ...(isClass ? { id: slot?.id } : {}),
    slug,
    scheduleId: slot?.scheduleId!,
    start: new Date(slot?.start!).valueOf(),
    end: new Date(slot?.end!).valueOf(),
    staffMemberId: slot?.resource?.id!,
    locationId: slot?.location?.id ?? '',
  };
};

const getSelectedOptionValueBySelectable = (
  selectable: Selectables,
  selectedOptions?: SelectedSlotOption[],
): string | undefined => {
  const filteredSelectedOption = selectedOptions?.filter(
    (selectedOption: SelectedSlotOption) => selectedOption.key === selectable,
  );
  return filteredSelectedOption?.[0]?.value;
};

export const getSelectedSlot = ({
  selectableSlots,
  dateRegionalSettingsLocale,
  t,
  selectedOptions,
}: {
  selectableSlots: SlotAvailability[];
  dateRegionalSettingsLocale: string;
  t: TFunction;
  selectedOptions?: SelectedSlotOption[];
}): SlotAvailability[] => {
  return selectableSlots.filter((selectableSlot: SlotAvailability) => {
    const locationSelectedOption = getSelectedOptionValueBySelectable(
      Selectables.LOCATION,
      selectedOptions,
    );
    const staffMemberSelectedOption = getSelectedOptionValueBySelectable(
      Selectables.STAFF_MEMBER,
      selectedOptions,
    );

    const durationSelectedOption = getSelectedOptionValueBySelectable(
      Selectables.DURATION,
      selectedOptions,
    );

    const selectedLocation =
      !locationSelectedOption ||
      selectableSlot.slot?.location?.id === locationSelectedOption;

    const selectedStaffMember =
      !staffMemberSelectedOption ||
      selectableSlot.slot?.resource?.id === staffMemberSelectedOption;

    const selectedDuration =
      !durationSelectedOption ||
      getSlotDuration(
        selectableSlot.slot?.start!,
        selectableSlot.slot?.end!,
        t,
        dateRegionalSettingsLocale,
      ) === durationSelectedOption;
    return selectedLocation && selectedStaffMember && selectedDuration;
  });
};
