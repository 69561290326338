import { ViewModelFactoryParams } from '../../../../utils/ControlledComponent/ControlledComponent.types';
import { WixOOISDKAdapter } from '@wix/bookings-adapter-ooi-wix-sdk';
import {
  OfferedAsType,
  ServicePayment,
  ServicePaymentDto,
} from '@wix/bookings-uou-types';
import {
  PaymentDtoMapper,
  isServiceOfferedAsPricingPlan,
} from '@wix/bookings-uou-mappers';
import { formatRfcTimeStringToDateAndTime } from '../../../../utils/dateAndTime/dateAndTime';
import {
  getSelectables,
  SelectableBookingDetails,
} from '../../../../utils/selectableBookingDetails/selectableBookingDetails';
import { CalendarContext } from '../../../../utils/context/contextFactory';
import { CalendarState } from '../../controller';
import settingsParams from '../../settingsParams';

export type BookingDetailsViewModel = {
  serviceName: string;
  dateAndTime?: string;
  paymentDescription?: string;
  selectableBookingDetails: SelectableBookingDetails[];
  bookingDetailsPreferencesHeaderText: string;
  bookingDetailsClearText: string;
  bookingDetailsCtaText: string;
  videoConferenceBadgeText?: string;
};

export async function createBookingDetailsViewModel({
  state,
  context,
}: ViewModelFactoryParams<
  CalendarState,
  CalendarContext
>): Promise<BookingDetailsViewModel> {
  const { businessInfo, t, settings, wixSdkAdapter } = context;
  const {
    selectedService,
    selectableSlots,
    selectedTime,
    selectedOptions,
    calendarErrors,
  } = state;

  const {
    bookingDetailsPreferencesHeaderText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
    bookingDetailsCtaText,
  } = getSettingsTexts(settings, state);

  const dateRegionalSettingsLocale = businessInfo.dateRegionalSettingsLocale!;
  const serviceName = selectedService.info.name;

  const paymentDescription = await getPaymentDescription(
    wixSdkAdapter,
    selectedService.payment,
    dateRegionalSettingsLocale,
    bookingDetailsPricingPlanText,
  );

  const defaultViewModel = {
    serviceName,
    paymentDescription,
    bookingDetailsPreferencesHeaderText,
    bookingDetailsClearText,
    bookingDetailsCtaText,
  };

  let dateAndTime;
  let selectableBookingDetails: SelectableBookingDetails[] = [];
  if (selectableSlots && selectedTime) {
    dateAndTime = formatRfcTimeStringToDateAndTime(
      selectedTime,
      dateRegionalSettingsLocale,
    );
    selectableBookingDetails = getSelectables({
      selectableSlots,
      calendarErrors,
      t,
      settings,
      dateRegionalSettingsLocale,
      selectedOptions,
    });
  }
  const videoConferenceBadgeText = selectedService.videoConferenceProviderId
    ? settings.get(settingsParams.videoConferenceBadgeText)
    : '';
  return {
    ...defaultViewModel,
    dateAndTime,
    selectableBookingDetails,
    videoConferenceBadgeText,
  };
}

const getPaymentDescription = async (
  wixSdkAdapter: WixOOISDKAdapter,
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
  bookingDetailsPricingPlanText: string,
) => {
  const paymentDescription = [];

  const isServiceBookableWithPricingPlan = await wixSdkAdapter.isPricingPlanInstalled();
  if (isOfferedAsOneTime(payment)) {
    const priceText = getPriceText(payment, dateRegionalSettingsLocale);
    paymentDescription.push(priceText);
  }
  if (
    isServiceOfferedAsPricingPlan(payment, isServiceBookableWithPricingPlan)
  ) {
    paymentDescription.push(bookingDetailsPricingPlanText);
  }
  return paymentDescription.join(' | ');
};

const isOfferedAsOneTime = (payment: ServicePayment) => {
  return payment.offeredAs.indexOf(OfferedAsType.ONE_TIME) >= 0;
};

const getPriceText = (
  payment: ServicePayment,
  dateRegionalSettingsLocale: string,
) => {
  const paymentDto: ServicePaymentDto = payment.paymentDetails;
  const paymentDtoMapper = new PaymentDtoMapper(dateRegionalSettingsLocale);
  return paymentDtoMapper.priceText(paymentDto);
};

const getSettingsTexts = (
  settings: CalendarContext['settings'],
  state: CalendarState,
) => {
  const bookingDetailsPreferencesHeaderText = settings.get(
    settingsParams.bookingDetailsPreferencesHeaderText,
  );
  const bookingDetailsPricingPlanText = settings.get(
    settingsParams.bookingDetailsPricingPlanText,
  );
  const bookingDetailsClearText = settings.get(
    settingsParams.bookingDetailsClearText,
  );
  const bookingFlaw = getBookingFlow(state);
  const bookingDetailsCtaText = settings.get(bookingFlaw);

  return {
    bookingDetailsPreferencesHeaderText,
    bookingDetailsPricingPlanText,
    bookingDetailsClearText,
    bookingDetailsCtaText,
  };
};

const getBookingFlow = (state: CalendarState) => {
  const isRescheduling = !!state.rescheduleBookingDetails;
  const isPendingApprovalFlow =
    state.selectedService?.policy?.isPendingApprovalFlow;

  if (isRescheduling) {
    return settingsParams.bookingDetailsRescheduleText;
  } else if (isPendingApprovalFlow) {
    return settingsParams.bookingDetailsPendingApprovalText;
  }
  return settingsParams.bookingDetailsBookNowText;
};
